.App {
  text-align: center;
}
.Graph {
  border: black;
  border-style: groove;
  width: 80%;
  height: 80%;
  align-content: center;
}

#wrap {
  width: 1100px;
  margin-right: auto;
  margin-left: 75px;
  padding-bottom: 150px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border: thin solid #000000;
  background: #ffffff;
}
.cernlogo {
  border-color: black;
  border-style: solid;
  margin-top: 18%;
  width: 20%;
  margin-left: 1px;
  margin-right: 1px;
  height: 10%;
}
.IMClogo {
  border-color: black;
  border-style: solid;
  width: 40%;
  height: 20%;
}
#topimg_nomargin {
  left: 5px;
  width: 60%;
  overflow: hidden;
  display: flex;
  margin-top: 5px;
  left: 0px;
  margin-left: 0;
  overflow: hidden;
}
#flex div {
  flex: 1;
  margin: 1px;
}
.ResearchInfrastructure_image {
  width: 60%;
}
.IMCatUio-footer {
  text-align: right;
  margin-top: 5%;
  font-size: calc(2px + 2vmin);
}
div.IMCatUioImage {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}
.caption {
  display: block;
}
#inhalt div.figure div.caption {
  font-size: 80%;
  margin: 0 0em;
  text-align: left;
  line-height: 1em;
}
.logo {
  width: min(50vw, 100px);
  margin-left: 25%;
}
.logo1 {
  width: min(50vw, 100px);
  margin-left: 15%
}
div.figure div.caption p {
  margin: 0.3em 0;
}

ul.collection {
  list-style: none;
  overflow: hidden;
  margin: 1em 0;
}

ul.collection li {
  float: left;
  margin: 2px;
}

.nohighslide .collection li {
  float: none !important;
  margin-bottom: 1em;
}

ul.collection a {
  display: block;
  width: 136px;
  height: 150px;
  padding: 2px;
  overflow: hidden;
  line-height: 142px;
  background: #f0f0f0;
  border: 1px solid #ddd;
}

li.collection a img {
  vertical-align: middle;
  max-width: 136px;
}

ul.collection.small a {
  max-width: 105px;
  max-height: 113px;
}
.figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.figurezbson {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.figureatlas {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 2%;
  margin-right: 2%;
  display: inline-block;
}
.Title {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: inherit;
  font-size: calc(10px + 2vmin);
}
.highslide:hover img {
  border-color: gray;
  border-top-color: gray;
  border-right-color: gray;
  border-bottom-color: gray;
  border-left-color: gray;
}
.highslide img {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.highslide {
  border: 2px solid silver;
  border-top-style: solid;
  border-top-width: 2px;
  border-right-color: silver;
  border-right-style: solid;
  border-right-width: 2px;
  border-bottom-color: silver;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: silver;
  border-left-style: solid;
  border-left-width: 2px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
}
.highslide-caption {
  display: none;
  font-size: 1em;
  padding: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.header {
  display: flex;
  align-items: center;
  margin-left: 40vw;
}

.App-header {
  background-color: #306f91;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 2vmin);
  color: rgb(253, 252, 252);
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-right: 5%;
}
.App-Footer {
  background: #182c3b;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(253, 252, 252);
  justify-content: space-between;
}

.footer-copyright {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35%;
  margin-left: 5vw;

}
.footer-add {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35%;
  margin-left: 35vw;

}


.App-Content {
  justify-content: center;
  min-height: 80vh;
  font-size: calc(12px + 1vmin);
  color: rgb(3, 3, 3);
  /* margin-left: calc(24vw + 5vw); */
  margin-left: 360px;
  margin-right: 2%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  background-color: #fcfdfd;
}

.List {
  justify-items: left;
  align-content: flex-start;
  text-align: left;
  margin-left: 1vw;
  margin-right: 1vw;
  font-family: Cambria, Georgia, "Times New Roman", serif;
}

.App-link {
  color: #61dafb;
}

p {
  font-family: Cambria, Georgia, "Times New Roman", serif;
  font-style: normal;
  font-weight: normal;
  font-size: calc(12px + 0.8vmin);
}
h2 {
  font-size: calc(18px + 1vmin);
}
.image {
  width: 50%;
  height: 50%;
  align-content: center;
  justify-items: center;
  flex-direction: column;
}

.imagetext {
  font-size: 12px;
}

.Publications {
  font-size: 16px;
}
.Navlink {
  display: flex;
  color: #ffffff;
  left: initial;
  justify-content: left;
  align-items: left;
  padding: 20px;
  padding-left: 10px;
  list-style: none;
  height: FLEX;
  text-decoration: none;
  font-size: 18px;
}


.Navlink:hover {
  font-weight: bolder;
  cursor: pointer;
}
.Navlink1 {
  display: flex;
  color: black;
  justify-content: left;
  align-items: center;
  padding: 1px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: calc(12px + 2px);
  border-color: 1px solid #d4d4d4;
  border-color: #d4d4d4;
  border-style: solid;
  border-width: 1px;
  margin-right: 5%;
}
.Navlink1:hover {
  background: #f15621;
  color: white;
  cursor: pointer;
}
.emphasize {
  font-style: italic;
  font-family: Cambria, Georgia, "Times New Roman", serif;
}
.Navlinksub {
  display: flex;
  color: black;
  justify-content: left;
  align-items: center;
  padding: 1px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: calc(12px + 1px);
  border-color: 1px solid #d4d4d4;
  border-color: #d4d4d4;
  border-style: solid;
  border-width: 1px;
  margin-right: 5%;
  padding-left: 10%;
  justify-content: left;
  word-wrap: break-word;
}
.Navlinksub:hover {
  background: rgb(78, 139, 170);
  color: white;
  cursor: pointer;
}
.Ulstyle li {
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: left;
  /* background-color: #3a9bd3; */
  color: #f15621;
  margin-left: 2px;
  margin-right: 2px;
  list-style: none;
}
.Ulstyle {
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
  color: #f15621;
  margin-left: 2px;
  margin-right: 2px;
  border-color: black solid;
  list-style: none;
}

.Ulstyle:hover {
  color: #f15621;
}
.Ulstyle1 li {
  align-items: center;
  margin-left: 1px;
  margin-right: 1px;
  color: black;
}
.Ulstyle1 {
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
  background-color: white;
  color: black;
  margin-left: 1px;
  margin-right: 1px;
}

.active {
  color: #f15621;
  font-weight: bold;
  font-size: calc(15px + 1vmin);
}
.Titleactive {
  color: none !important;
  background-color: none;
}
.map-image {
  width: 70%;
  height: 20vmax;
  vertical-align: middle;
}
.TitleIMC {
  font-size: calc(10px + 1vmin);
}

@media only screen and (max-width: 600px) {

  
.footer{
  background: #182c3b;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: rgb(253, 252, 252);
    width: 100%;	
    margin: auto;
}
  .App-Footer {
  	width: 100%;	
	margin: auto;
    background: #182c3b;
    min-height: 10vh;
    display: flex;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: rgb(253, 252, 252);
    width: 100%;	
    margin: auto;
  }

  .footer-copyright{
    width: 45%;
   
  }
  .footer-add{
    width: 35%;
    margin-right: auto;
    }
    
    
    
  .App-Content {
    justify-content: center;
    min-height: 80vh;
    font-size: calc(10px + 1vmin);
    color: rgb(3, 3, 3);
    margin-left: 2%;
    margin-right: 2%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #fcfdfd;
  }
  .Navlink1 {
    display: flex;
    color: black;
    justify-content: left;
    align-items: center;
    padding: 1px;
    list-style: none;
    text-decoration: none;
    font-size: calc(12px + 2px);
    border-color: 1px solid #d4d4d4;
    border-color: #d4d4d4;
    border-style: solid;
    word-wrap: break-word;
  }
  .Ulstyle1 {
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
    background-color: white;
    color: black;
    margin-left: 0px;
    margin-right: 1px;
    word-wrap: break-word;
  }
  .IMCimg {
    width: 100%;
    height: auto;
  }
  .logo {
    width: min(50vw, 80px);
  }
  .logo1 {
    width: min(50vw, 80px);
    margin-left: 5%;
    margin-right: 1%
  }
  .header {
    display: flex;
    align-items: center;
    margin-left: 15vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 980px) {
  .logo {
    width: min(50vw, 100px);
  }
  .logo1 {
    width: min(50vw, 100px);
    margin-right: 0%;
    padding-right: 0%;
  }
  .App-Content {
    justify-content: center;
    min-height: 80vh;
    font-size: calc(10px + 1vmin);
    color: rgb(3, 3, 3);
    margin-left: 360px;
    margin-right: 2%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #fcfdfd;
  }
  .header {
    display: flex;
    align-items: center;
    margin-left: 50vw;
  }

  .footer{
    background: #182c3b;
      min-height: 10vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: calc(10px + 2vmin);
      color: rgb(253, 252, 252);
      width: 100%;	
      margin: auto;
  }
    .App-Footer {
      width: 100%;	
      margin: auto;
      background: #182c3b;
      min-height: 10vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: calc(10px + 2vmin);
      color: rgb(253, 252, 252);
      float:left;
      width: 100%;	
      margin: auto;
   
    }
  
    .footer-copyright {
    width: 25%;
    float: left;
    margin-left: 60vw;
  
    }
    .footer-add{
    width: 35%;
    float: left;
    margin-left: 60vw;
    }
  .IMCimg {
    width: 100%;
    height: auto;
  }
  .Navlink1 {
    display: flex;
    color: black;
    justify-content: left;
    align-items: center;
    list-style: none;
    text-decoration: none;
    font-size: calc(12px + 2px);
    border-color: 1px solid #d4d4d4;
    border-color: #d4d4d4;
    border-style: solid;
    border-width: 1px;
    margin-right: 5%;
  }
  .map-image {
    width: 90%;
    height: "480";
    align-content: center;
    justify-items: center;
    flex-direction: column;
  }
}
.Ulstylesub li {
  align-items: center;
  margin-left: 1px;
  margin-right: 1px;
  color: black;
  list-style-type: none;
  background-color: white;
}
.Ulstylesub {
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
  background-color: white;
  color: black;
  margin-left: 1px;
  margin-right: 1px;
  list-style-type: none;
}

.Ulstyle1sub li {
  align-items: center;
  margin-left: 1px;
  margin-right: 1px;
  color: black;
  list-style-type: none;
  background-color: white;
  align-content: center;
  justify-items: center;
  flex-direction: column;
}
.Ulstyle1sub {
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
  background-color: white;
  color: black;
  margin-left: 1px;
  margin-right: 1px;
  list-style-type: none;
  align-content: center;
  justify-items: center;
  flex-direction: column;
}
